import mobileCarousel from "../modules/utils/mobileCarousel";

mobileCarousel('section-icons__icon-group--slider', 'section-icons__arrow', 1.25, 0, false);

const sections = document.querySelectorAll(".section-icons");

if (sections?.length > 0) {

    const handleCardIconTitleHeight = (list) => {
        const cards = list.querySelectorAll(".card-icon");
        if (!cards || cards.length === 0) return;
        let highestTitleSize = 0;

        cards.forEach(card => {
            const title = card.querySelector(".card-icon__heading");
            if (!title) return;
            title.style.height = `auto`;
        });

        cards.forEach(card => {
            const title = card.querySelector(".card-icon__heading");
            if (!title) return;
            const titleHeight = title.offsetHeight;
            if (titleHeight > highestTitleSize) highestTitleSize = titleHeight;
        });

        if (highestTitleSize > 0) {
            cards.forEach(card => {
                const title = card.querySelector(".card-icon__heading");
                if (!title) return;
                title.style.height = `${highestTitleSize}px`;
            });
        }

    }

    sections.forEach(section => {
        const iconList = section.querySelectorAll(".section-icons__icons-list");

        iconList.forEach(list => {
            window.addEventListener("resize", () => handleCardIconTitleHeight(list));
            handleCardIconTitleHeight(list);
        });
    });
}